import Image from "next/image";
import Slider from "react-slick";
import Autolinker from "autolinker";
import { useState, useEffect } from "react";
import { NextArrow, PrevArrow } from "./ArrowCarousel";
import { ConvertDateToTimeSince } from "../helpers/util";
import styles from "../styles/YoutubeCarousel.module.scss";

const YoutubeCarousel = ({ rssYoutubeFeed }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [activeVideo, setActiveVideo] = useState({});
  const [device, setDevice] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  let slider1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    slider2 = [];

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const handleCustomPlayClick = (i, device) => {
    setActiveVideo({ ...activeVideo, [i]: !activeVideo[i] });
    setDevice(device);
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,

    beforeChange: (curr, next) => {
      setActiveVideo({ ...activeVideo, [curr]: false });
      setActiveSlide(next);
    },
  };

  const settingsSliderNav = {
    dots: false,
    arrows: true,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (curr, next) => {
      setActiveVideo({ ...activeVideo, [curr]: false });
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const autolinker = new Autolinker({
    urls: true,
    email: false,
    phone: false,
    mention: false,
    hashtag: "twitter", // placeholder
    replaceFn: (match) => {
      switch (match.getType()) {
        case "url":
          return `<a aria-hidden="true" href="${match.getAnchorHref()}" target="_blank">Read more &raquo;</a>`;
        case "hashtag":
          return `<a aria-hidden="true" href="https://www.youtube.com/hashtag/${match.getHashtag()}" target="_blank">#${match.getHashtag()}</a>`;
      }
    },
  });

  const autoLinkDescription = (description) => {
    const text = description.replace("Read:", "").replace("Full story:", "");
    return autolinker.link(text);
  };

  return (
    rssYoutubeFeed &&
    rssYoutubeFeed.length > 0 && (
      <div className={styles.youTubeCarousel}>
        <div className={styles.mainCarousel}>
          <Slider
            asNavFor={nav2}
            ref={(slider) => (slider1 = slider)}
            {...settings}
          >
            {rssYoutubeFeed.map((rss, i) => {
              return (
                <div className={styles.videoContainer} key={i}>
                  {!activeVideo[i] && (
                    <div
                      className={styles.videoOverlay_main}
                      onClick={() => {
                        handleCustomPlayClick(i, "mobile");
                      }}
                    >
                      <div className={styles.imageWrap_main}>
                        <Image
                          src={`https://i2.ytimg.com/vi/${rss.ytVideoId}/maxresdefault.jpg`}
                          alt={rss.title}
                          layout="fill"
                          objectFit="contain"
                          priority
                        />
                      </div>
                      <button
                        aria-hidden="true"
                        className={styles.customButton}
                      />
                    </div>
                  )}
                  {!!activeVideo[i] && device === "mobile" && (
                    <iframe
                      id={`election-${i}`}
                      src={`https://www.youtube.com/embed/${rss.ytVideoId}?controls=0&autoplay=1`}
                      title={rss.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  )}
                  <p
                    className={styles.videoDesc}
                    dangerouslySetInnerHTML={{
                      __html: autoLinkDescription(
                        rss.media["media:description"].toString()
                      ),
                    }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>

        <div
          className={`${styles.navCarousel} ${styles.desktop}  videoCarousel`}
        >
          <Slider
            asNavFor={nav1}
            ref={(slider) => (slider2 = slider)}
            {...settingsSliderNav}
          >
            {rssYoutubeFeed.map((rss, i) => (
              <div className={styles.videoContainerNav} key={i}>
                <div className={styles.imageWrap}>
                  <div className={styles.thumbnailDesktop}>
                    <Image
                      src={`https://i2.ytimg.com/vi/${rss.ytVideoId}/hqdefault.jpg`}
                      className={styles.thumbnail}
                      alt={rss.title}
                      layout="fill"
                      objectFit="cover"
                      priority
                    />
                  </div>
                  <div className={styles.thumbnailMobile}>
                    {!activeVideo[i] && (
                      <div
                        className={styles.videoOverlay}
                        onClick={() => {
                          handleCustomPlayClick(i, "desktop");
                        }}
                      >
                        <Image
                          src={`https://i2.ytimg.com/vi/${rss.ytVideoId}/maxresdefault.jpg`}
                          className={styles.thumbnail}
                          alt={rss.title}
                          layout="fill"
                          objectFit="contain"
                          priority
                        />

                        <button
                          aria-hidden="true"
                          className={styles.customButton}
                        />
                      </div>
                    )}
                  </div>
                  {!!activeVideo[i] && device === "desktop" && (
                    <iframe
                      src={`https://www.youtube.com/embed/${rss.ytVideoId}?controls=0&autoplay=1`}
                      title={rss.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  )}
                </div>
                <h3 className={styles.videoDesc}>{rss.title}</h3>
                <p className={styles.pubDate}>
                  {ConvertDateToTimeSince(rss.pubDate)}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  );
};

export default YoutubeCarousel;

import Link from "next/link";
import Image from "next/image";
import { useState } from "react";
import Slider from "react-slick";
import Loader from "../../Loader";
import useSWRImmutable from "swr/immutable";
import { NextArrow, PrevArrow } from "../../ArrowCarousel";
import { ConvertDateToTimeSince } from "../../../helpers/util";
import styles from "../../../styles/homepage/parts/TopicCarousel.module.scss";

const cfUrl = process.env.API_CF_URL;
const TopicCarousel = () => {
  const topics = {
    news: "philippine-elections-2022-news",
    inDepth: "philippine-elections-2022-in-depth-stories",
    voices: "philippine-elections-2022-voices-opinion",
    multimedia: "philippine-elections-2022-multimedia",
    dataCharts: "philippine-elections-2022-data-charts",
    movements: "philippine-elections-2022-movements-community-engagement",
  };
  const [currentTopic, setCurrentTopic] = useState(topics.news);

  const TopicFeed = ({ topicName }) => {
    const fetcher = (url, body) =>
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      }).then(async (res) => {
        return res.json();
      });
    // const { data, error } = useSWR(
    //   `/api/feed/candidate?slug=${topicName}`,
    //   fetcher,
    //   {
    //     refreshInterval: 0,
    //     shouldRetryOnError: true,
    //     errorRetryInterval: 1500,
    //     errorRetryCount: 1
    //   }
    // );
    const { data: rawData, error } = useSWRImmutable(
      [
        `${cfUrl}/getRSS`,
        JSON.stringify({
          data: {
            type: "news",
            slug: topicName,
          },
        }),
      ],
      fetcher,
      {
        refreshInterval: 0,
        shouldRetryOnError: true,
        errorRetryInterval: 1500,
        errorRetryCount: 1,
      }
    );
    const data = rawData?.result;

    if (!error && !data) return <Loader color={styles.textColor} />;
    if (error) return <p>Something went wrong, Please try again later!</p>;

    return data.map((rss, i) => (
      <article key={i} className={styles.articleWrapper}>
        <div className={styles.gradientBG}>
          <Link href={rss.link} passHref>
            <a className={styles.articlesContent} target="_blank">
              <h3>{rss.title}</h3>
              <p>
                {rss.title.length + rss.content.length > 200
                  ? rss.content.slice(0, 120) + "..."
                  : rss.content}
              </p>
              <p className={styles.pubDate}>
                {ConvertDateToTimeSince(rss.pubDate)}
              </p>
            </a>
          </Link>
        </div>
        <Image
          className={styles.imgBG}
          src={rss.image}
          alt={rss.title}
          layout="fill"
          objectFit="cover"
        />
      </article>
    ));
  };

  const activeTopic = (selectedTopic) =>
    currentTopic === selectedTopic ? styles.highlight : "";

  const settings = {
    slidesToShow: 4.3,
    slidesToScroll: 2,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    lazyload: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className={styles.carouselContainer}>
      <h2>Your Guide to the 2022 Philippine Elections</h2>
      <div className={styles.topicsContainer}>
        <button
          className={activeTopic(topics.news)}
          onClick={() => setCurrentTopic(topics.news)}
        >
          News
        </button>
        <button
          className={activeTopic(topics.inDepth)}
          onClick={() => setCurrentTopic(topics.inDepth)}
        >
          In-depth
        </button>
        <button
          className={activeTopic(topics.voices)}
          onClick={() => setCurrentTopic(topics.voices)}
        >
          Voices
        </button>
        <button
          className={activeTopic(topics.multimedia)}
          onClick={() => setCurrentTopic(topics.multimedia)}
        >
          Multimedia
        </button>
        <button
          className={activeTopic(topics.dataCharts)}
          onClick={() => setCurrentTopic(topics.dataCharts)}
        >
          Data
        </button>
        <button
          className={activeTopic(topics.movements)}
          onClick={() => setCurrentTopic(topics.movements)}
        >
          Movements
        </button>
      </div>

      <Slider {...settings}>{TopicFeed({ topicName: currentTopic })}</Slider>

      <Link href="https://www.rappler.com/nation/elections/things-to-know-guides-electoral-process-philippines-2022/">
        <a className={styles.viewPhGuideBtn}>VIEW #PHVOTE GUIDE </a>
      </Link>
    </div>
  );
};

export default TopicCarousel;

import Image from "next/image";
import logo from "../../../../public/images/ATIN-PILIPINAS.png";
import styles from "../../../styles/homepage/parts/SkinBanner.module.scss";

const SkinBanner = () => {
  const loader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <div className={styles.skinBannerContainer}>
      <div className={styles.skinBannerContainer_img}>
        <Image
          loader={loader}
          src={logo}
          alt="atin ang pilipinas"
          width={200}
          height={110}
        />
      </div>
      <h3>2022 PHILIPPINE ELECTION RESULTS</h3>
    </div>
  );
};

export default SkinBanner;

import styles from "../../../styles/homepage/parts/TwitterWidget.module.scss";

const TwitterWidget = () => {
  return (
    <div className={styles.twitterWidgetContainer}>
      <div className={styles.twitterList}>
        <a
          className="twitter-timeline"
          href="https://twitter.com/rapplerdotcom?ref_src=twsrc%5Etfw"
        >
          Tweets by rapplerdotcom
        </a>
      </div>
      <div className={styles.twitterList}>
        <a
          className="twitter-timeline"
          href="https://twitter.com/phvote?ref_src=twsrc%5Etfw"
          target="_blank"
          rel="noreferrer"
        >
          Tweets by phvote
        </a>
      </div>
      {/* <div className={styles.latestNews}></div> */}
    </div>
  );
};
export default TwitterWidget;

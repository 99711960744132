import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import GenericMaleThumbnail from "../../public/images/thumbs-generic-male-1.png";
import GenericFemaleThumbnail from "../../public/images/thumbs-generic-female-1.png";
import ArrowDown from "../../public/images/icons/angle-down-black.png";
import styles from "../styles/TaleOfTheTapeWidget.module.scss";

const TaleOfTheTapeWidget = ({ presidential, vPresidential }) => {
  const [selectedRace, setSelectedRace] = useState(0);
  const [openSelection, setOpenSelection] = useState(-1);
  const [candidates, setCandidates] = useState(presidential);
  const [maxCandidate, setMaxCandidate] = useState(0);
  const [isCandidateSelected, setIsCandidateSelected] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([
    null,
    null,
    null,
  ]);

  useEffect(() => {
    setCandidates(selectedRace === 0 ? presidential : vPresidential);
    setMaxCandidate(Math.ceil((candidates?.results?.length || 0) / 2));
  }, [presidential, vPresidential, candidates, selectedRace]);

  const loader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  const selectRace = (index) => {
    if (index !== selectedRace) {
      setSelectedRace(index);
      setOpenSelection(-1);
      setSelectedCandidates([null, null, null]);
      setIsCandidateSelected(false);
    }
  };

  const toggleSelection = (index) => {
    if (index !== openSelection) {
      setOpenSelection(index);
    } else if (index === openSelection) {
      setOpenSelection(-1);
    }
  };

  const selectCandidate = (candidate, index = -1) => {
    if (index === -1) {
      const selectedCandidate = selectedCandidates.findIndex(
        (x) => x?.id === candidate.id
      );
      if (selectedCandidate === -1) {
        const newIndex = selectedCandidates.findIndex((x) => !x?.id);
        if (newIndex !== -1) {
          setSelectedCandidates(
            selectedCandidates.map((x, i) => {
              return i === newIndex ? candidate : x;
            })
          );
        }
        setIsCandidateSelected(true);
      } else {
        setSelectedCandidates(
          selectedCandidates.map((x, i) => {
            return i === selectedCandidate ? null : x;
          })
        );

        const selectedCandidatesCount = 0;
        selectedCandidates.forEach((sc) => {
          if (sc !== null) {
            selectedCandidatesCount++;
          }
        });

        if (selectedCandidatesCount - 1 < 1) {
          setIsCandidateSelected(false);
        }
      }
    } else {
      setSelectedCandidates(
        selectedCandidates.map((x, i) => {
          return i === index ? candidate : x;
        })
      );
      setIsCandidateSelected(true);
    }
  };

  return (
    <div className={styles.taleOfTheTapeWidgetWrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.title}>
            {"Compare your Candidates".toUpperCase()}
          </h3>
          <p className={styles.subtitle}>
            Get to know your candidates stances, issues and platforms. You can
            select up to <span className={styles.count}>3</span>.
          </p>
        </div>
        <div className={styles.race}>
          <button
            className={`${styles.selection} ${
              selectedRace === 0 && styles.selection__selected
            }`}
            onClick={() => selectRace(0)}
          >
            For President
          </button>

          <button
            className={`${styles.selection} ${
              selectedRace === 1 && styles.selection__selected
            }`}
            onClick={() => selectRace(1)}
          >
            For Vice President
          </button>
        </div>
        <div className={styles.candidateSelectionWrapper}>
          {selectedCandidates.map((x, index) => (
            <button
              key={index}
              className={styles.candidateSelection}
              onClick={() => toggleSelection(index)}
            >
              <div className={styles.arrow}>
                <Image
                  loader={loader}
                  src={ArrowDown}
                  alt={"arrowDown"}
                  width={10}
                  height={7}
                />
              </div>
              <div className={styles.placeholder}>
                {x?.id
                  ? `${x.last_name}, ${x.alternate_name || x.first_name} (${
                      x.political_party
                    })`
                  : "Select a Candidate"}
              </div>
              {openSelection === index && (
                <div className={styles.selectionItem}>
                  {candidates?.results &&
                    candidates?.results?.length &&
                    candidates?.results.map(
                      (candidate, i) =>
                        selectedCandidates.findIndex(
                          (x) => x?.id === candidate.id
                        ) === -1 && (
                          <div
                            key={i}
                            className={styles.candidateSelection}
                            onClick={() => selectCandidate(candidate, index)}
                          >
                            {candidate.last_name},{" "}
                            {candidate.alternate_name || candidate.first_name} (
                            {candidate.political_party})
                          </div>
                        )
                    )}
                </div>
              )}
            </button>
          ))}
          {[0, 1].map((x) => (
            <div key={x} className={styles.candidateOptions}>
              {candidates?.results &&
                candidates?.results?.length &&
                candidates?.results.map(
                  (candidate, index) =>
                    index >= x * maxCandidate &&
                    index < (x + 1) * maxCandidate && (
                      <div
                        key={index}
                        className={`${styles.candidate} ${
                          selectedCandidates.findIndex(
                            (x) => x?.id === candidate.id
                          ) !== -1 && styles.candidate__selected
                        }`}
                        onClick={() => selectCandidate(candidate)}
                      >
                        {candidate.thumbnail ? (
                          <Image
                            src={candidate.thumbnail}
                            alt={candidate.first_name}
                            layout="fixed"
                            width={55}
                            height={55}
                            className={styles.thumbnail}
                          />
                        ) : (
                          <Image
                            loader={loader}
                            src={
                              (candidate.gender || "").toLowerCase() ===
                              "female"
                                ? GenericFemaleThumbnail
                                : GenericMaleThumbnail
                            }
                            alt="default image"
                            layout="fixed"
                            width={55}
                            height={55}
                            className={styles.thumbnail}
                          />
                        )}
                        <span className={styles.candidateDetails}>
                          <span>{candidate.ballot_no}</span>
                          <span className={styles.lastName}>
                            {candidate.last_name}
                          </span>
                          <span className={styles.firstName}>
                            {candidate.alternate_name || candidate.first_name} (
                            {candidate.political_party})
                          </span>
                        </span>
                      </div>
                    )
                )}
            </div>
          ))}
        </div>
        {isCandidateSelected ? (
          <Link
            href={{
              pathname:
                "/elections/2022/races/president-vice-president/tale-of-the-tape",
              query: {
                selectedCandidates: selectedCandidates
                  .filter((x) => x?.id && x?.id > 0)
                  .map((x) => x?.id)
                  .join(","),
                race: selectedRace === 0 ? "presidential" : "vice-presidential",
              },
            }}
          >
            <a className={`${styles.button} ${styles.active}`}>
              Compare these Candidates
            </a>
          </Link>
        ) : (
          <button className={styles.button}>Compare these Candidates</button>
        )}
      </div>
      <div className={styles.overlay} />
    </div>
  );
};
export default TaleOfTheTapeWidget;

import { useState, useEffect } from "react";
import { SeoTag, CxenseParseTag } from "../components/seo/SeoTag";
import Header from "../components/Header";
import Banner from "../components/Banner";
import HomePage from "../components/homepage/HomePage";
import GlobalMenu from "../components/GlobalMenu";
import Footer from "../components/Footer";
import styles from "../styles/homepage/Home.module.scss";
import {
  getPresidentList,
  getVicePresidentList,
  getSenatorList,
  getPartyList,
  getPartyListMain,
  getAllPartyList,
  getRegions,
} from "./api/ph-api";

export const getStaticProps = async ({ query }) => {
  let regionList = await getRegions();
  let presidential = await getPresidentList();
  let vPresidential = await getVicePresidentList();
  let senatorialList = await getSenatorList();
  let partyList = await getPartyList();
  let partyListMain = await getPartyListMain();
  let allPartyList = await getAllPartyList();

  if (
    !regionList ||
    !presidential ||
    !vPresidential ||
    !senatorialList ||
    !partyList ||
    !partyListMain ||
    !allPartyList
  ) {
    return {
      props: {
        presidential,
        vPresidential,
        senatorialList,
        partyList,
        partyListMain,
        allPartyList,
        regionList,
      },
      revalidate: 5,
    };
  }

  return {
    props: {
      presidential,
      vPresidential,
      senatorialList,
      partyList,
      partyListMain,
      allPartyList,
      regionList,
    },
  };
};

const Home = ({
  presidential,
  vPresidential,
  senatorialList,
  partyList,
  partyListMain,
  allPartyList,
  regionList,
}) => {
  const [url, setUrl] = useState("");
  const title = "2022 Philippine election results";
  const description =
    "News, candidates and results of the 2022 Philippine presidential, vice presidential, senatorial, party list, and local elections.";
  const keywords =
    "2022 Philippine elections, #PHVote, Philippine elections, election results Philippines";
  const og_image = "https://www.rappler.com/tachyon/2022/03/ATIN-PILIPINAS.jpg";

  useEffect(() => {
    setUrl(window.location.href);

    const script = document.createElement("script");
    script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    script.setAttribute("async", "true");
    document.head.appendChild(script);
  }, []);

  return (
    <div className={styles.container}>
      <SeoTag
        title={title}
        description={description}
        keywords={keywords}
        og_image={og_image}
        twitter_og_image={og_image}
        twitter_title={title}
        twitter_description={description}
      />
      <CxenseParseTag title={title} />
      <Header />
      <Banner />
      <GlobalMenu />
      <HomePage
        regionList={regionList}
        presidential={presidential}
        vPresidential={vPresidential}
        senatorialList={senatorialList}
        partyList={partyList}
        partyListMain={partyListMain}
        allPartyList={allPartyList}
        url={url}
      />
      <Footer />
    </div>
  );
};

export default Home;

import Link from "next/link";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Ads } from "../../ads/Ads";
import Loader from "../../Loader";
import YoutubeCarousel from "../../YoutubeCarousel";
import { GlobalShare } from "../../SocialButtons";
import { getArticleHeader } from "../../../helpers/tachyon";
import { ConvertDateToTimeSince } from "../../../helpers/util";
import styles from "../../../styles/homepage/parts/FirstSection.module.scss";
import Curator from "../../Curator";

const cfUrl = process.env.API_CF_URL;
const FirstSection = ({ url }) => {
  const [rssFeed, setRssFeed] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [rssThumbnail, setRssThumbnail] = useState("");
  const [rssYoutubeFeed, setRssYoutubeFeed] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let isMounted = true;

    const getRss = async () => {
      const feedResponse = await fetch(`${cfUrl}/getRSS`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: "news",
            topic: "election"
          }
        })
      });
      const { result: electionFeeds } = await feedResponse.json();

      const responseYT = await fetch(`${cfUrl}/getRSS`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: "yt",
            topic: "election"
          }
        })
      });
      const { result: youtubeFeeds } = await responseYT.json();

      setRssFeed(electionFeeds);
      setRssYoutubeFeed(youtubeFeeds);
      setRssThumbnail(getArticleHeader(electionFeeds?.[0].image));
    };

    if (isMounted) {
      getRss();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return rssFeed && rssFeed.length > 0 ? (
    <>
      <div className={styles.firstSection}>
        <div className={styles.sectionWrapper}>
          <div className={styles.sectionGrid}>
            {/* <YoutubeCarousel rssYoutubeFeed={rssYoutubeFeed} /> */}
            <Curator />
          </div>
          <div className={styles.sectionGrid}>
            <div className={styles.globalShare_hp_desktop}>
              <GlobalShare
                url={url}
                title="Share rappler’s 2022 Elections coverage"
                fbQuote="2022 Philippine Elections – Candidates, races and results #PHVote #WeDecide"
                tWQuoute="2022 Philippine Elections – Candidates, races and results #PHVote #WeDecide"
                toolTipId={"toolTip_Default"}
                isMounted={isMounted}
              />
            </div>
            <div className={styles.adMiddle1}>
              <Ads
                deviceType="desktop"
                id="ad-homepage-middle1-desktop"
                adType="middle1"
                pageType="elec_homepage"
              />
              <Ads
                deviceType="mobile"
                id="ad-homepage-middle1-mobile"
                adType="middle1"
                pageType="elec_homepage"
              />
            </div>
            <div className={styles.latestNewsWrapper}>
              <h3 className={styles.latestNewsHeader}>Latest Election News</h3>
              <article>
                <Link href={rssFeed[0].link} passHref>
                  <a>
                    {/* <p className={styles.category}>{rssFeed[0].categories[0]}</p> */}
                    {rssThumbnail && (
                      <div className={styles.imgWrapper}>
                        <Image
                          src={rssThumbnail}
                          alt={rssFeed[0].title}
                          layout="responsive"
                          width={100}
                          height={60}
                        />
                      </div>
                    )}
                    <h3 className={styles.latestNewsTitle}>
                      {rssFeed[0].title}
                    </h3>

                    <p className={styles.pubDate}>
                      {ConvertDateToTimeSince(rssFeed[0].pubDate)}
                    </p>
                  </a>
                </Link>
              </article>
              {(rssFeed || []).slice(1, 3).map((rss) => (
                <article key={rss.guid}>
                  <Link href={rss.link} passHref>
                    <a>
                      {/* <p className={styles.category}>{rss.categories[0]}</p> */}
                      <h3 className={styles.latestNewsTitle}>{rss.title}</h3>
                      <p className={styles.pubDate}>
                        {ConvertDateToTimeSince(rss.pubDate)}
                      </p>
                    </a>
                  </Link>
                </article>
              ))}
              <Link href="https://www.rappler.com/topic/2022-philippine-elections/">
                <a className={styles.viewPhGuideBtn}>MORE ELECTION NEWS</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default FirstSection;

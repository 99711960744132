/**
 * Tachyon documentation: https://docs.altis-dxp.com/media/dynamic-images/
 * This functions only works for images hosted in tachyon. The image manipulation is done
 * through tachyon cloud by providing query parameters on the original image url.
 * Please refer to documentation when setting custom sizes, cropping etc.
 */

const IMAGE_SIZES = {
    thumbnail: {
        width: 150,
        height: 150
    },
    medium: {
        width: 300,
        height: 200
    },
    medium_large: {
        width: 768,
        height: 512
    },
    large: {
        width: 1024,
        height: 683
    },
    ra_article_header: {
        width: 644,
        height: 362
    },
    ra_teaser_hero: {
        width: 545,
        height: 545
    },
    ra_teaser_compact: {
        width: 160,
        height: 160
    },
    ra_related_article: {
        width: 499,
        height: 299
    },
    ra_topic_header: {
        width: 1200,
        height: 485
    }
}

const CROP_STRATEGY = {
    smart: "smart",
    entropy: "entropy",
    attention: "attention"
}

function setSize(size) {
    return `resize=${size.width},${size.height}`;
}

function setZoom(zoomLevel) {
    return `zoom=${zoomLevel}`
}

function setCrop({size}) {
    return `crop=${size.width},${size.height}`
}

function setFit(size) {
    return `fit=${size.width},${size.height}`
}

function setCropStrategy(strategy) {
    return `crop_strategy=${strategy}`
}

export function getTachyonThumbnail(url) {
    return `${url}?${setSize(IMAGE_SIZES.thumbnail)}`
}

export function getArticleHeader(url) {
    return `${url}?${setSize(IMAGE_SIZES.ra_article_header)}&${setCropStrategy(CROP_STRATEGY.attention)}&test`
}
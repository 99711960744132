import { useState } from "react";
import { Ads, StickyAds } from "../ads/Ads";
import { GlobalShare } from "../SocialButtons";
import Widget from "./parts/Widget";
import RaceWidget from "../RaceWidget";
import FirstSection from "./parts/FirstSection";
import SkinBanner from "./parts/SkinBanner";
import TwitterWidget from "./parts/TwitterWidget";
import PartyList from "../partylist/PartyListWidget";
import TopicCarousel from "./parts/TopicCarousel";
import TaleOfTheTapeWidget from "../TaleOfTheTapeWidget";
import styles from "../../styles/homepage/Home.module.scss";

const HomePage = ({
  regionList,
  presidential,
  vPresidential,
  senatorialList,
  partyList,
  partyListMain,
  allPartyList,
  presidentDataChart,
  vicePresidentDataChart,
  url,
}) => {
  const [isMounted, setIsMounted] = useState(true);

  return (
    <main className={`${styles.main} ${`body_wrapper`}`}>
      <div className={styles.globalShare_hp}>
        <GlobalShare
          url={`${url}elections/2022/`}
          title="Share rappler’s 2022 Elections coverage"
          fbQuote="2022 Philippine Elections – Candidates, races and results #PHVote #WeDecide"
          tWQuoute="2022 Philippine Elections – Candidates, races and results #PHVote #WeDecide"
          toolTipId={"toolTip_Default"}
          isMounted={isMounted}
        />
      </div>

      <Ads
        deviceType="mobile"
        id="ad-homepage-billboard-mobile"
        adType="billboard"
        pageType="elec_homepage"
      />
      <Ads
        deviceType="desktop"
        id="ad-homepage-billboard-desktop"
        adType="billboard"
        pageType="elec_homepage"
      />
      {/* <FirstSection url={`${url}elections/2022/`} />  */}

      <SkinBanner />

      <div className={styles.widgetWrapper}>
        <RaceWidget
          candidatesList={presidential?.results}
          regionList={regionList?.results}
          contestName="presidential race"
          raceName="PRESIDENT PHILIPPINES"
          description="The winner will become the 17th president of the Philippines, to serve for six years starting noon of June 30, 2022. "
          readMoreLink="https://www.rappler.com/nation/elections/president-vp-philippines-powers-duties/"
          quote="Partial, official 2022 presidential and vice presidential election results #PHVote #WeDecide #PHVoteResults"
          urlPath="elections/2022/candidates/"
          url={`${url}elections/2022/races/president-vice-president/results`}
          dataChart={presidentDataChart}
          display={styles.remove}
          withResultsFooter={false}
        />
        <RaceWidget
          candidatesList={vPresidential?.results}
          regionList={regionList?.results}
          contestName="vice presidential race"
          raceName="VICE PRESIDENT PHILIPPINES"
          description="The winner will become the 15th vice president of the Philippines, to serve for six years, starting noon of June 30, 2022. "
          readMoreLink="https://www.rappler.com/nation/elections/president-vp-philippines-powers-duties/"
          quote="Partial, official 2022 presidential and vice presidential election results #PHVote #WeDecide #PHVoteResults"
          urlPath="elections/2022/candidates/"
          url={`${url}elections/2022/races/president-vice-president/results`}
          dataChart={vicePresidentDataChart}
          display={styles.remove}
          withResultsFooter={false}
        />
      </div>

      <Ads
        deviceType="desktop"
        id="ad-homepage-leaderboard1-desktop"
        adType="leaderboard1"
        pageType="elec_homepage"
      />
      <Ads
        deviceType="mobile"
        id="ad-homepage-leaderboard1-mobile"
        adType="leaderboard1"
        pageType="elec_homepage"
      />

      <div className={styles.widgetWrapper}>
        {/* <Widget
          contestName="senatorial"
          title="WHO’S GUNNING FOR A SEAT IN THE SENATE?"
          subTitle="Get to know the 64 candidates in the running."
          description="The 12 persons with the most votes will join the 24-member Senate, the upper chamber of the bicameral Congress of the Philippines. They will serve for six years starting noon of June 30, 2022."
          readMore="https://www.rappler.com/nation/elections/senator-philippines-powers-duties/"
          urlPath="/elections/2022/races/senator"
        />
        <Widget
          contestName="party list"
          title="WHAT ARE THE PARTY LIST GROUPS RUNNING FOR SEATS IN THE LOWER HOUSE?"
          subTitle="Get to know the party list groups and and their nominees."
          description="The party list groups with the most votes can each send up to three nominees to the House of Representatives, depending on the number of votes they garner against the combined total of all groups. They will serve for three years starting June 30, 2022."
          readMore="https://www.rappler.com/nation/elections/district-party-list-representative-philippines-power-duties/"
          urlPath="/elections/2022/races/party-list"
        /> */}

        <RaceWidget
          candidatesList={senatorialList?.results}
          regionList={regionList?.results}
          contestName="senatorial race"
          raceName="SENATOR PHILIPPINES"
          description="The 12 persons with the most votes will join the 24-member Senate, the upper chamber of the bicameral Congress of the Philippines. They will serve for six years starting noon of June 30, 2022"
          readMoreLink="https://www.rappler.com/nation/elections/senator-philippines-powers-duties/"
          quote="Partial, official 2022 senatorial election results #PHVote #WeDecide #PHVoteResults"
          urlPath="elections/2022/candidates/"
          url={`${url}elections/2022/races/senator/results`}
          marginTop={styles.marginTop}
          display={styles.remove}
          withResultsFooter={false}
        />
        <PartyList
          raceName="PARTY LIST PHILIPPINES"
          minified={true}
          homeWidget={true}
          regionList={regionList?.results}
          view="results"
          partyList={partyList}
          partyListMain={partyListMain}
          allPartyList={allPartyList}
          url={url}
          miniPagination={false}
          viewAllLink={`${url}elections/2022/races/party-list/results`}
          withResultsFooter={false}
          isEmbed={false}
        />
      </div>
      <Ads
        deviceType="mobile"
        id="ad-homepage-leaderboard2-mobile"
        adType="leaderboard2"
        pageType="elec_homepage"
      />

      <div className={styles.taleWidgetRow}>
        <TaleOfTheTapeWidget
          presidential={presidential}
          vPresidential={vPresidential}
        />
        <div className={styles.adMiddle2}>
          <Ads
            deviceType="desktop"
            id="ad-homepage-middle2-desktop"
            adType="middle2"
            pageType="elec_homepage"
          />
        </div>
      </div>

      <TopicCarousel />

      <Ads
        deviceType="mobile"
        id="ad-homepage-middle2-mobile"
        adType="middle2"
        pageType="elec_homepage"
      />

      <TwitterWidget />
      <StickyAds
        deviceType="desktop"
        id="ad-homepage-sticky-desktop"
        pageType="elec_homepage"
      />
      <StickyAds
        deviceType="mobile"
        id="ad-homepage-sticky-mobile"
        pageType="elec_homepage"
      />
    </main>
  );
};

export default HomePage;
